import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { Row, Col } from "react-bootstrap"

import { MainLayout } from "../components/containers"
import SEO from "../components/SEO"
import { Container, H3, Description, H2 } from "../components/elements"
import { ContactForm } from "../components/forms"

const Wrapper = styled.section`
  padding-top: 75px;
  blockquote {
    border-left: 5px ${props => props.theme.colors.primary} solid;
    padding-left: 20px;
  }
  h3 {
    margin-bottom: 20px;
  }
  .descri {
    margin-bottom: 100px;
  }
  a,
  a:hover {
    color: ${props => props.theme.colors.primary};
  }
  nav {
    margin-top: 100px;
  }
  hr {
    margin: 50px 0;
  }
  .quote {
    margin-top: 100px;
    h2 {
      font-size: 4.5rem;
      color: ${props => props.theme.colors.text};
    }
  }
  .gallery {
    margin-bottom: 100px;
    .image {
      height: 600px;
    }
  }
  @media screen and (max-width: 576px) {
    padding-top: 50px;
    blockquote {
      border-left: 5px ${props => props.theme.colors.primary} solid;
      padding-left: 20px;
    }
    .gallery {
      margin-bottom: 40px;
      .image {
        height: 300px;
      }
    }
    h3 {
      margin-bottom: 20px;
    }
    .descri {
      margin-bottom: 40px;
    }
    nav {
      margin-top: 40px;
    }

    .quote {
      margin-top: 40px;
      h2 {
        font-size: 3rem;
      }
    }
  }
`

const StyledImg = styled(Img)`
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
`

const ServiceContentulTemplate = ({ data, pageContext }) => {
  const service = data.contentfulService
  const site = data.site.siteMetadata
  const { previous, next } = pageContext

  return (
    <MainLayout>
      <SEO title={service.title} description={service.summary} />
      <Container>
        <Wrapper>
          <article>
            <header>
              <H3>{service.title}</H3>
            </header>
            <div className="d-flex justify-content-lg-end descri">
              <Col lg={4} className="p-0">
                <Description>{service.summary}</Description>
              </Col>
            </div>
            <Row className="gallery">
              {service.gallery.map(img => {
                return (
                  <Col lg={6} className="mb-5 image">
                    <StyledImg alt={img.title} fluid={img.fluid} />
                  </Col>
                )
              })}
            </Row>
            <section
              dangerouslySetInnerHTML={{
                __html: service.content.childContentfulRichText.html,
              }}
            />
          </article>

          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.slug} rel="prev">
                    ← {previous.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.slug} rel="next">
                    {next.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
          <Col lg={5} className="p-0 quote">
            <H2>{site.quote}</H2>
          </Col>
        </Wrapper>
        <ContactForm />
      </Container>
    </MainLayout>
  )
}

export default ServiceContentulTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        quote
      }
    }
    contentfulService(slug: { eq: $slug }) {
      title
      summary
      gallery {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      content {
        childContentfulRichText {
          html
        }
      }
    }
  }
`
